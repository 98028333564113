<template>
  <div class="information">
      <h1>403</h1>
      <h3>Доступ запрещен</h3>
  </div>
</template>
<script>

export default {

  data: () => ({
    
  }),
  computed: {
    
  },
  methods: {
  }
}
</script> 
